import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/invite/:id',
    name: 'Invite',
    component: () => import('../views/Invite.vue'),
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('../views/ForgotPassword.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/search/Search.vue')
  },
  {
    path: '/discoverynew',
    name: 'discoverynew',
    component: () => import('../components/DiscoveryProcess/Step3.vue')
  },
  {
    path: '/search/q/:id/bucket/:bid',
    name: 'Search List',
    component: () => import('../views/search/SearchList.vue')
  },
  {
    path: '/discovery',
    name: 'Discovery',
    component: () => import('../views/discovery-process/DiscoveryProcessStep1.vue'),
    meta: {
      layout: 'discoveryProcess'
    },
    // component: () => import('../views/Discovery.vue'),
    // meta: {
    //   layout: 'discovery'
    // },
  },
  {
    path: '/discovery/jobs/:hashid',
    name: 'Discovery-Analysis',
    component: () => import('../views/discovery-process/DiscoveryAnalysis.vue'),
    meta: {
      layout: 'discoveryProcess'
    },
  },
  {
    path: '/discovery/jobs',
    name: 'Discovery-Jobs',
    component: () => import('../views/DiscoveryJobs.vue'),
  },
  {
    path: '/discovery/process-step2',
    name: 'Discovery Process Step 2',
    component: () => import('../views/discovery-process/DiscoveryProcessStep2.vue'),
    meta: {
      layout: 'discoveryProcess'
    },
  },
  {
    path: '/buckets',
    name: 'BucketsOverview',
    component: () => import('../views/BucketsOverview.vue')
  },
  {
    path: '/buckets/:id',
    name: 'Buckets',
    component: () => import('../views/buckets/Buckets.vue')
  },
  {
    path: '/bucket-admin',
    name: 'Bucket-Admin',
    component: () => import('../views/bucket-admin/BucketAdmin.vue'),
    meta: {
      layout: 'bucketAdmin'
    },
  },
  {
    path: '/bucket-admin/:id',
    name: 'Bucket-Admin-Edit',
    component: () => import('../views/bucket-admin/BucketAdmin.vue'),
    meta: {
      layout: 'bucketAdmin'
    },
  },
  {
    path: '/bucket-admin-created/:id',
    name: 'Bucket-Admin-Created-Item',
    component: () => import('../views/bucket-admin/BucketAdminCreated.vue')
  },
  {
    path: '/bucket-admin/configuration',
    name: 'Bucket-Admin-Configuration',
    component: () => import('../views/bucket-admin/BucketAdminConfiguration.vue'),
    meta: {
      layout: 'bucketAdmin'
    },
  },
  {
    path: '/bucket-admin/configuration/channels',
    name: 'Bucket-Admin-Configuration-Channels',
    component: () => import('../views/bucket-admin/BucketAdminChannels.vue'),
    meta: {
      layout: 'bucketAdmin'
    },
  },
  {
    path: '/bucket-admin/configuration/videos',
    name: 'Bucket-Admin-Configuration-Videos',
    component: () => import('../views/bucket-admin/BucketAdminVideos.vue'),
    meta: {
      layout: 'bucketAdmin'
    },
  },
  {
    path: '/bucket/:id/channel/',
    name: 'Buckets-Channels',
    component: () => import('../views/buckets/Channels.vue')
  },
  {
    path: '/buckets/videos/yt/:id',
    name: 'Buckets-Videos',
    component: () => import('../views/buckets/Videos.vue')
  },
  {
    path: '/track',
    name: 'Track',
    component: () => import('../views/Track.vue')
  },
  {
    path: '/trends',
    name: 'Trends',
    component: () => import('../views/Trends.vue')
  },
  {
    path: '/saved',
    name: 'Saved',
    component: () => import('../views/Saved.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/settings/Settings.vue'),
    meta: {
      layout: 'settings'
    },
  },
  {
    path: '/settings/account',
    name: 'Settings-Account',
    component: () => import('../views/settings/SettingsAccount.vue'),
    meta: {
      layout: 'settings'
    },
  },
  {
    path: '/settings/billing-plans',
    name: 'Settings-Billing-Plans',
    component: () => import('../views/settings/SettingsBillingPlans.vue'),
    meta: {
      layout: 'settings'
    },
  },
  {
    path: '/settings/billing-plans/request',
    name: 'Settings-Billing-Plans-Requet-Free-Access',
    component: () => import('../views/settings/SettingsBillingPlansRequest.vue'),
    meta: {
      layout: 'settings'
    },
  },
  {
    path: '/settings/billing-plans/contact',
    name: 'Settings-Billing-Plans-Team-Plan',
    component: () => import('../views/settings/SettingsBillingPlansContact.vue'),
    meta: {
      layout: 'settings'
    },
  },
  {
    path: '/settings/api',
    name: 'Settings-API',
    component: () => import('../views/settings/SettingsAPI.vue'),
    meta: {
      layout: 'settings'
    },
  },
  {
    path: '/settings/labels',
    name: 'Settings-Labels',
    component: () => import('../views/settings/SettingsLabels.vue'),
    meta: {
      layout: 'settings'
    },
  },
  {
    path: '/settings/team',
    name: 'Settings-Team',
    component: () => import('../views/settings/SettingsTeam.vue'),
    meta: {
      layout: 'settings'
    },
  },
  // {
  //   path: '/onboarding',
  //   name: 'Onboarding',
  //   component: () => import('../views/Onboarding.vue'),
  //   meta: {
  //     layout: 'onboarding'
  //   },
  // },
  // {
  //   path: '/onboardingtypeform',
  //   name: 'OnboardingTypeform',
  //   component: () => import('../views/OnboardingTypeform.vue'),
  //   meta: {
  //     layout: 'onboarding'
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.afterEach((to) => {
//   store.dispatch('general/track', to);
// })

export default router

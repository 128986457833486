// import axios from 'axios';

const state = {
  search: {
    close_pip_scroll: true,
    close_details_scroll: true
  },
  account: {
    name: "",
    email: "",
    uuid: "",
    role: "",
    org_type: ""
  },
  billing: {},
  api: {}
}
const getters = {
  close_pip_scroll: (state) => state.search.close_pip_scroll,
  close_details_scroll: (state) => state.search.close_details_scroll,
}
const mutations = {
  set_close_pip_scroll(state, payload){
    state.search.close_pip_scroll = payload
  },
  set_close_details_scroll(state, payload){
    state.search.close_details_scroll = payload
  }
}
const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
// import axios from 'axios';

const state = {
    bucket: {
      bucketId: '',
      title: "",
      bucketType: "",
      description: "",
      tags: [],
      methodology: "",
      geographicalfocus: ""
    },
    channels: [
      ""
    ], 
    videos: [
      ""
    ],
    isUpdate: false
  }
  const getters = {
    bucket: (state) => state.bucket,
    isUpdateBucket: (state) => state.isUpdate,
  }
  const mutations = {
    setBucketItem(state, payload){
      state.bucket[payload.key] = payload.value
    },
    setIsUpdate(state, payload){
      state.isUpdate = payload
    },
  }
  const actions = {
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
  }
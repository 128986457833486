import axios from 'axios';
// import gql from 'graphql-tag'

const state = {
  jobData: {
    analysisRunning: false,
    discoveryId: "",
    graph: ""
  },
  channelData: {
    channelId: 'UCW39zufHfsuGgpLviKh297Q',
    channelTitle: 'DW Documentary',
    channelAvatar: null
  },
  auth: "",
  analysis_name: "",
  analysis_channels: [],
  description: '',
  total_comments: 0,
  videos: 0,
  seedlist: [],
  seedvideos: [],
  email_notification: true,
}
const getters = {
  seedlist: (state) => state.seedlist,
  analysis_name: (state) => state.analysis_name,
  description: (state) => state.description,
  channelData: (state) => state.channelData,
  jobData: (state) => state.jobData,
  analysis_channels: (state) => state.analysis_channels,
}
const mutations = {
  setAuth(state, payload) { // refactor?
    state.auth = payload;
  },
  addVideoToSeedlist(state, payload) {
    state.seedlist.push(payload)

    console.log(state);
  },
  removeVideoFromSeedlist(state, payload) {
    state.seedlist = state.seedlist.filter(elem => elem.video_id != payload)
  },
  clearSeedList(state) {
    state.seedlist = []
  },
  setAnalysisName(state, payload) {
    state.analysis_name = payload;
  },
  setAnalysisChannels(state, payload) {
    state.analysis_channels = JSON.parse(JSON.stringify(payload))
  },
  setDescription(state, payload) {
    state.description = payload;
  },
  // V2
  startAnalysis(state, payload) {
    console.log('this is starting the analysis')
    console.log(payload.channelIds)
    state.jobData.discoveryId = payload.discoveryId;
    state.jobData.analysisRunning = payload.analysisRunning;
  },
  setChannelData(state, payload) {
    state.channelData.channelId = payload.channelId
    state.channelData.channelTitle = payload.channelTitle;
    state.channelData.channelAvatar = payload.channelAvatar
  }
}
const actions = {
  async authorizeAuth0(context) {
    await this._vm.$auth.getTokenSilently()
      .then((res) => {
        context.commit('setAuth', res);
        console.log(res);
      })
  },
  async getVideo (context, payload) {
    await context.dispatch('authorizeAuth0');

    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get_video/${payload.video_id}`, 
      {},
      // {
      //   headers: {
      //     Authorization: `Bearer ${context.state.auth}`,
      //     token: '0Iwb1fxwt4MDku4zsWrsqQu2rFEjU3ko'
      //   }
      // }
      );

    context.commit('addVideoToSeedlist', {
      video_id: data.response.video_id,
      author_name: data.response.author_name,
      title: data.response.title,
      thumb: data.response.thumbnails[2].url
    })
  },
  startAnalysis (context, payload) {
    context.commit('startAnalysis', {
      discoveryId: payload.hash,
      analysisRunning: true,
      channelIds: payload
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
<template>
  <div >
    <div v-if="$auth.isAuthenticated" class="flex flex-wrap w-full justify-center">
      <div class="w-7/12 flex flex-wrap justify-center">
        <p class="mt-36 text-6xl text-black font-black text-center w-full">Welcome</p>
        <p class="text-2xl font-normal base-font-gray-200 text-center mt-12 w-full">Thank you for signing up.</p>
        <p class="text-2xl font-normal base-font-gray-200 text-center w-full">Here’s how you can get started.</p>

        <div class="flex flex-wrap w-full justify-center">
          <div class="w-1/2 mt-32 relative px-4">
            <div class="step bg-white absolute left-1/2 border-grey-light-140">
              <div class="step-one flex items-center justify-center w-12 h-12 rounded-full text-2xl border">1</div>
            </div>
            <div class="step-one-line absolute h-16 w-0.5 border-dashed border left-1/2 -mx-px"></div>
            <img :src="require('@/assets/images/promo.png')" class="promo absolute" />
            <div class="px-7 pt-14 pb-4 border rounded border-grey-light-130 text-center">
              <p class="text-black font-normal text-xl text-center">Create a Bucket</p>
              <p class="base-font-gray-200 font-normal text-base text-center mt-4">
                Raditube stores YouTube channels and videos in buckets. You can either create your 
                own bucket or search within a curated community bucket.
              </p>
              <router-link 
                to="/bucket-admin"
                class="mt-6 px-3 py-1 border border-grey-darker-100 inline-block font-monoDemi text-xs rounded-2xl whitespace-nowrap text-black"
              >
                Create a Bucket
              </router-link>
            </div>

            <img 
              :src="require('@/assets/images/dashboard-figure-left.svg')"
              class="absolute figure-left" 
            />
          </div>
        </div>

        <div class="w-1/2 mt-44 relative px-4">
          <div class="step bg-white absolute left-1/2 border-grey-light-140">
            <div class="flex items-center justify-center w-12 h-12 rounded-full text-2xl text-black base-bg-darker-150">2</div>
          </div>
          <div class="px-7 pt-14 pb-4 border rounded border-grey-light-130 text-center">
            <p class="text-black font-normal text-xl text-center">Community Bucket Search</p>
            <p class="base-font-gray-200 font-normal text-base text-center mt-4">
              You can either search within your own custom bucket, but you can also search within buckets created by our community members.
            </p>
            <form @submit.prevent="setSearch" class="flex items-end mt-3">
              <div class="relative search-input w-full pr-4">
                <input 
                  v-model="searchTerm"
                  type="text"
                  placeholder="keyword"
                  class="text-base p-2 outline-none border-b border-black w-full text-black placeholder-black"
                  required
                >
              </div>
              <button
                class="px-3 py-1 border border-grey-darker-100 inline-block font-monoDemi text-xs rounded-2xl whitespace-nowrap text-black"
                type="submit"
              > 
                Search
              </button>
            </form>
          </div>
        </div>

        <div class="w-1/2 mt-44 relative px-4">
          <div class="step bg-white absolute left-1/2 border-grey-light-140">
            <div class="flex items-center justify-center w-12 h-12 rounded-full text-2xl text-black base-bg-darker-150">3</div>
          </div>
          <div class="px-7 pt-14 pb-4 border rounded border-grey-light-130 text-center">
            <p class="text-black font-normal text-xl text-center">Channel Discovery</p>
            <p class="base-font-gray-200 font-normal text-base text-center mt-4">
              One of the challenges with YouTube <br>
              is finding relevant channels. 
            </p>
            <router-link 
              to="/discovery"
              class="mt-6 px-3 py-1 border border-grey-darker-100 inline-block font-monoDemi text-xs rounded-2xl whitespace-nowrap text-black"
            >
              Start a Discovery
            </router-link>
          </div>
        </div>

        <div class="flex flex-wrap w-full justify-end">
          <div class="w-1/2 mt-40 relative px-4">
            <div class="step bg-white absolute left-1/2 border-grey-light-140">
              <div class="flex items-center justify-center w-12 h-12 rounded-full text-2xl text-black base-bg-darker-150">4</div>
            </div>
            <div class="step-four-line absolute h-28 w-0.5 border-dashed border left-1/2 -mx-px"></div>
            <div class="absolute w-52 add-channels">
              <p class="font-normal text-sm text-black mb-1">Add Channels</p>
              <p class="font-normal text-xs base-font-gray-200">
                Add YouTube channels to your bucket, RadiTube automatically checks for newly added videos.
              </p>
            </div>
            <div class="px-7 pt-14 pb-4 border rounded border-grey-light-130 text-center">
              <p class="text-black font-normal text-xl text-center">Personal Bucket Search</p>
              <p class="base-font-gray-200 font-normal text-base text-center mt-4 mb-12">
                Search within your personally curated bucket, and start a qualitative narrative analysis.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap w-full mb-48">
        <p class="w-full text-black font-normal text-2xl text-center mt-14 ">Team Features</p>
        <p class="w-full font-normal text-xl text-center base-font-gray-200 mt-5">Collaborate with team members</p>
        <div class="w-2/6 px-4 mt-12">
          <div class="flex items-start base-bg-100 border rounded-lg border-grey-light-130">
            <div class="w-1/4 flex justify-center pt-10 pb-4">
              <img :src="require('@/assets/images/comment-purple.svg')" alt="">
            </div>
            <div class="w-3/4 pt-10 pb-4 pr-6">
              <p class="font-normal font-grey-darker text-xl mb-4">Slack integration</p>
              <p class="font-normal text-base base-font-gray-light-110 mb-4">
                Get notified on Slack when a team member starts a new bucket, discovery or adds a new channel.
              </p>
              <router-link 
                to="/"
                class="px-3 py-1 border border-grey-darker-100 inline-block font-monoDemi text-xs rounded-2xl whitespace-nowrap text-black"
              >
                Add Slack Integration
              </router-link>
            </div>
          </div>
          <div class="flex items-start base-bg-100 border rounded-lg border-grey-light-130 mt-7">
            <div class="w-1/4 flex justify-center pt-10 pb-4">
              <img :src="require('@/assets/images/users-purple.svg')" alt="">
            </div>
            <div class="w-3/4 pt-10 pb-4 pr-6">
              <p class="font-normal font-grey-darker text-xl mb-4">Collaborative Buckets</p>
              <p class="font-normal text-base base-font-gray-light-110 mb-4">
                Share a bucket with the members on your team.
              </p>
              <router-link 
                to="/"
                class="px-3 py-1 border border-grey-darker-100 inline-block font-monoDemi text-xs rounded-2xl whitespace-nowrap text-black"
              >
                Create a Team Bucket
              </router-link>
            </div>
          </div>
        </div>
        <div class="w-2/6 px-4 mt-12">
          <div class="flex items-start base-bg-100 border rounded-lg border-grey-light-130 h-full">
            <div class="w-1/4 flex justify-center pt-10 pb-4">
              <img :src="require('@/assets/images/pen-tool-purple.svg')" alt="">
            </div>
            <div class="flex flex-wrap w-3/4 pt-10 pb-4 pr-6 h-full flex-col justify-between">
              <div>
                <p class="font-normal font-grey-darker text-xl mb-4 w-full">Narrative Tracing</p>
                <p class="font-normal text-base base-font-gray-light-110 mb-4 w-full">
                  Use collaborative searching to trace how narratives on YouTube evolve over time.
                </p>
              </div>
              <div>
                <router-link 
                  to="/"
                  class="px-3 py-1 border border-grey-darker-100 inline-block font-monoDemi text-xs rounded-2xl whitespace-nowrap text-black"
                >
                  Coming Soon
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="w-2/6 px-4 mt-12">
          <div class="flex items-start base-bg-100 border rounded-lg border-grey-light-130 h-full">
            <div class="w-1/4 flex justify-center pt-10 pb-4">
              <img :src="require('@/assets/images/note-text-purple.svg')" alt="">
            </div>
            <div class="flex flex-wrap w-3/4 pt-10 pb-4 pr-6 h-full flex-col justify-between">
              <div>
                <p class="font-normal font-grey-darker text-xl mb-4 w-full">Collaborative Note Taking</p>
                <p class="font-normal text-base base-font-gray-light-110 mb-4 w-full">
                  Point to specific timestamps. 
                </p>
              </div>
              <div>
                <router-link 
                  to="/"
                  class="px-3 py-1 border border-grey-darker-100 inline-block font-monoDemi text-xs rounded-2xl whitespace-nowrap text-black"
                >
                  Coming Soon
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Login
      v-else
      emailText="Sign in with Email"
      title="Sign in"
      btnText="Sign in"
      :isLogin="true"
    />
  </div>
</template>

<script>
import Login from '@/components/login/Login'

export default {
  name: 'Home',
  components: {
    Login
  },
  data: () => {
    return {
      searchTerm: `"do your own research"`
    }
  },
  methods: {
    setSearch(){
      this.$router.push(`/search/q/${this.searchTerm}/bucket/bid_EqxN2Lm54`)
    }
  }
}
</script>

<style lang="scss">
.step{
  transform: translateX(-50%) translateY(-50%);
  &-one{
    background: linear-gradient(288.43deg, #913EFB -312.5%, #B1DADD 278.41%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &-line{
      top: -98px;
    }
  }
  &-four{
    &-line{
      top: -154px;
    }
  }
}
.figure{
  &-left{
    bottom: -136px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
}
.add-channels{
  top: -50%;
  right: -18%;
  &::before{
    content: '';
    position: absolute;
    width: 2.3rem;
    height: 1px;
    background-color: #C4C4C4;
    top: 10px;
    left: -54px;
  }
}
.promo{
  top: -75%;
  right: -40%;
}
</style>
export const warningToastConfig = {
  theme: "toasted-primary", 
  position: "bottom-right", 
  duration : 5000,
  action : {
    text : 'Close',
    onClick : (e, toastObject) => {
        toastObject.goAway(0);
    }
  },
};
export const bucketTypes = [
  {
    title: 'Community Bucket',
    value: 'community_editor',
    bucketType: 'community',
    id: 3,
    icon: 'point-in-circles',
    disabled: false
  },
  {
    title: 'Private Bucket',
    value: 'private',
    bucketType: 'private',
    id: 1,
    icon: 'triangle-in-shield',
    disabled: false
  },
  {
    title: 'Team Bucket',
    value: 'team',
    bucketType: 'team',
    id: 2,
    icon: 'circles-on-circle',
    disabled: false
  },
  // {
  //   title: 'Community Plus Bucket',
  //   value: 'raditube_editor',
  //   bucketType: 'communityplus',
  //   id: 4,
  //   icon: 'plus-in-circles',
  //   disabled: false
  // }
]
export const bucketsAdminRights = {
  community: false,
  communityplus: false,
  private: [ 'private' ],
  team: [ 'private', 'team' ],
  community_editor: [ 'community_editor', 'raditube_editor' ],
  // raditube_editor: [ 'private', 'team', 'community_editor', 'raditube_editor' ],
  raditube_editor: [ 'private', 'team', 'community_editor' ]
}
export const bucketConfigOptions = [
  {
    title: 'Track Channels',
    id: 1,
    route: '/bucket-admin/configuration/channels',
    text: [
      'Checks a channel multiple times a day for new videos',
      'Adds new videos to the bucket',
      'This option is most suitable if you have a list of channels that you would like to track over time.'
    ]
  },
  {
    title: 'Seperate Videos',
    id: 2,
    route: '/bucket-admin/configuration/videos',
    text: [
      'Adds a fixed list of videos',
      'Does not check channels for newly uploaded videos',
      'This option is most suitable if you have a predefined list of videos that you would like to look into.'
    ]
  },
  {
    title: 'Both',
    id: 3,
    route: '/bucket-admin/configuration/channels',
    text: [
      'Combines both track channels and seperate videos'
    ]
  }
]
export const bucketConfigChannelOptions = [
  {
    title: 'Manually',
    value: 'manually',
    component: 'SidebarAddManually',
    id: 1
  },
  {
    title: 'Discovery',
    value: 'discovery',
    component: '',
    id: 2
  },
  // {
  //   title: 'CSV import',
  //   value: 'csv',
  //   component: 'SidebarAddCSV',
  //   id: 3
  // },
  // {
  //   title: 'API',
  //   value: 'api',
  //   component: '',
  //   id: 4
  // }
]
export const billingPlans = [
  {
    title: 'Community Plan',
    icon: 'pointInCircles',
    priceText: '$0/month',
    planName: 'community',
    list: [
      'Access to all the community buckets'
    ],
    id: 1
  },
  {
    title: 'Premium Plan',
    icon: 'triangleInShield',
    priceText: '$0/month',
    btnText: 'Click here to upgrade',
    planName: 'premium',
    list: [
      'All of the community plan',
      'Create up to 3 private buckets containing up to 30 channels each',
      'Access to the Discovery tool',
      'Note taking',
      'Export All Transcripts'
    ],
    id: 2
  },
  {
    title: 'Teams Plan',
    icon: 'circlesOnCircle',
    priceText: 'contact for pricing',
    btnText: 'Contact',
    btnLink: '/settings/billing-plans/contact',
    planName: 'team',
    list: [
      'All of the community and premium plan',
      'Create up to 5 buckets containing up to 100 channels each per team member',
      'Share buckets among your team',
      'Powerful collaboration tools',
      'Access to advanced features of the Discovery tool'
    ],
    id: 3
  },
]
export const qualifies = [
  {
    id: 0,
    title: 'Journalist',
    value: 'journalist'
  },
  {
    id: 1,
    title: 'Academic Researcher',
    value: 'academic-researcher'
  }
]
export const amountOfTeamMembers = [
  {
    id: 0,
    title: '2-10',
    value: '2-10'
  },
  {
    id: 1,
    title: '11-50',
    value: '11-50'
  },
  {
    id: 2,
    title: '51+',
    value: '51+'
  }
]
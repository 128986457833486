import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './styles/index.css'
import '@/assets/global.scss'

import axios from 'axios'
import VueFormulate from '@braid/vue-formulate'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueYoutube from 'vue-youtube'
import VueDraggableResizable from 'vue-draggable-resizable'
import JsonCSV from 'vue-json-csv'
import Toasted from 'vue-toasted';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VCalendar from 'v-calendar';
import VueLocalStorage from 'vue-localstorage'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import VuePapaParse from 'vue-papa-parse'
import VueScrollTo from 'vue-scrollto'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VModal from 'vue-js-modal'
import VueNumber from 'vue-number-animation'
// import VueSmoothScroll from 'vue2-smooth-scroll'
// Vue.use(VueSmoothScroll)

import ToastMessages from './data/toast_messages.json';

//Mixins
import routeGuard from './mixins/routeGuard'

// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

import { Auth0Plugin } from "./auth";
import auth0 from 'auth0-js'

Vue.use(VuePapaParse)
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -48,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

Vue.prototype.$webAuth = new auth0.WebAuth({
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientID: process.env.VUE_APP_CLIENTID,
  responseType: 'token id_token',
  redirectUri: window.location.origin,
  audience: process.env.VUE_APP_AUDIENCE,
  scope: ""
});

import { warningToastConfig } from '@/helpers/constants'

Vue.use(VueFormulate)
Vue.use(VueVirtualScroller)
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_CLIENTID,
  audience: process.env.VUE_APP_AUDIENCE,
  scope: "",
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});
Vue.use(VueYoutube)
Vue.use(Toasted)
Vue.use(require('vue-moment'));
Vue.use(VCalendar);
Vue.use(VueLocalStorage)
Vue.use(VModal)
Vue.use(VueNumber)

Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.component('downloadCsv', JsonCSV)
Vue.component('multiselect', Multiselect)
Vue.component('v-popover', VPopover)

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.directive('click-outside', {
  bind: function (el, binding) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
})

Vue.mixin(routeGuard)

Vue.filter('truncate', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});
Vue.filter('capitalizeFirstLetter', function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
});

let requestEndpoint = ''

axios.interceptors.response.use(
  function (response) {
    const data = typeof(response.data) == 'string' ? JSON.parse(response.data) : response.data
    if (requestEndpoint == 'search' && data.hits.total.value == 0) {
      Vue.toasted.show(ToastMessages.errors.no_results, warningToastConfig)
    }

    requestEndpoint = ''
    return response;
  }, 
  function (error) {
    if (error.response.status == 400) {
      Vue.toasted.show(ToastMessages.errors.invalid_request, warningToastConfig)
    }
    return Promise.reject(error);
});

axios.interceptors.request.use(function (config) {
  const token = `Bearer ${store.state.general.auth}`;
  config.headers.Authorization =  token;

  requestEndpoint = config.url.split('/')
  requestEndpoint = requestEndpoint[requestEndpoint.length - 1]
  
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

Vue.prototype.$axios = axios

export const eventBus = new Vue();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
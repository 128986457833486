import Vue from "vue";
import store from '../store/index.js'
import createAuth0Client from "@auth0/auth0-spa-js";
import { MetadataQuery } from '@/helpers/Queries'

/** Define a default action to perform after authentication */
// const DEFAULT_REDIRECT_CALLBACK = () =>
//   window.history.replaceState({}, document.title, window.location.pathname); // old logic

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  // onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, // old logic
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null
      };
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(options, config) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(options, config);
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.error = null;
        } catch (e) {
          this.error = e;
          // eslint-disable-next-line
          console.error(e);
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;

        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o);
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        // scope: 'openid profile email',
        scope: 'openid profile email user_metadata',
        redirect_uri: redirectUri
      });
      
      //New logic

      if(window.location.hash.indexOf('#access_token') != -1){
        this.$webAuth.parseHash({ hash: window.location.hash }, async (err, authResult) => {
          if (err) {
            this.isAuthenticated = false
            this.user = {}
            await store.dispatch('general/loggedIn', this.user);
            return console.error('Error in parseHash', err);
          }
          this.$webAuth.client.userInfo(authResult.accessToken, async (err, user) => {
            if (err) {
              return console.error('Error in userInfo', err);
            }
            this.isAuthenticated = true
            await store.dispatch('general/authorizeAuth0');
            try {
              this.user = await this.$mainClient.query(MetadataQuery(user.sub))
              await store.dispatch('general/loggedIn', { 
                ...this.user.data.getUser, 
                organization: this.user.data.getOrganizationByUser,
                teams: this.user.data.getTeams,
                picture: user.picture,
                name: user.name
              });
            } catch (error) {
              this.user = user
              await store.dispatch('general/loggedIn', this.user);
            }
            
      //       // Old logic

      //       // this.user = user
      //       // await store.dispatch('general/loggedIn', this.user);

            window.history.replaceState({}, document.title, window.location.pathname)
          });
        });
      } else {
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.auth0Client.getTokenSilently()
        // .then((res) => console.log(res))
        try {
          if(this.isAuthenticated){
            await store.dispatch('general/authorizeAuth0');
            let user = await this.auth0Client.getUser();
            this.user = await this.$mainClient.query(MetadataQuery(user.sub));

            await store.dispatch('general/loggedIn', { 
              ...this.user.data.getUser, 
              organization: this.user.data.getOrganizationByUser,
              teams: this.user.data.getTeams,
              picture: user.picture,
              name: user.name
            });
          }

        } catch (error) {
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.user = await this.auth0Client.getUser();
          store.dispatch('general/loggedIn', this.user);
        }
      }
      
      // Old auth logic

      // try {
      //   // If the user is returning to the app after authentication..
      //   if (
      //     window.location.search.includes("code=") &&
      //     window.location.search.includes("state=")
      //   ) {
      //     // handle the redirect and retrieve tokens
      //     const { appState } = await this.auth0Client.handleRedirectCallback();

      //     this.error = null;

      //     // Notify subscribers that the redirect callback has happened, passing the appState
      //     // (useful for retrieving any pre-authentication state)
      //     onRedirectCallback(appState);
      //   }
      // } catch (e) {
      //   this.error = e;
      // } finally {
      //   // Initialize our internal authentication state
      //   this.isAuthenticated = await this.auth0Client.isAuthenticated();
      //   this.user = await this.auth0Client.getUser();
      //   console.log(this.user, 'this.user')
      //   store.dispatch('general/loggedIn', this.user);
      //   this.loading = false;
      // }

      const user_metadata = JSON.parse(this.$localStorage.get('user_metadata'))
      if(user_metadata){
        store.commit('settings/set_close_pip_scroll', user_metadata.close_pip_scroll)
        store.commit('settings/set_close_details_scroll', user_metadata.close_details_scroll)
      }

      this.loading = false;
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
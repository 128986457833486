import axios from 'axios';
// import { mainClient } from '@/main'
// import { getNotesVideo } from '@/helpers/Queries'
import { formatNotesWithTimestamps } from '@/helpers/formats'

const state = {
  uuid: null,
  video: null,
  videoTranscription: null,
  videoNotes: null,
  videoUsers: null,
  videoScreenshots: []
}
const getters = {
  uuid: (state) => state.general,
  video: (state) => state.video,
  videoTranscription: (state) => state.videoTranscription,
  videoNotes: (state) => state.videoNotes,
  videoUsers: (state) => state.videoUsers,
  videoScreenshots: (state) => state.videoScreenshots,
}
const mutations = {
  setDirectVideo(state, payload){
    state.video = payload
  },
  setDirectVideoScreenshots(state, payload){
    state.videoScreenshots.push(payload)
  },
  setDirectVideoTranscription(state, payload){
    state.videoTranscription = payload
  },
  setDirectVideoNotes(state, payload){
    let notes = JSON.parse(JSON.stringify(payload));
    notes.forEach(note => {
      note.description = formatNotesWithTimestamps(note.description)
    });
    state.videoNotes = notes;
  },
  setDirectVideoUsers(state, payload){
    state.videoUsers = payload
  },
}
const actions = {
  async getDirectVideoDetails({ commit }, payload) {
    commit('setDirectVideo', null)
    let video = {
      data: ""
    }
    console.log(payload)
    // let video = await axios.get(`${process.env.VUE_APP_API_DOMAIN}/video_details/${payload}`);
    commit('setDirectVideo', video.data)
  },
  async getDirectVideoTranscription({ commit }, payload) {
    console.log(commit);
    console.log(payload);
    // commit('setDirectVideoTranscription', null)
    // let video = await axios.get(`${process.env.VUE_APP_API_DOMAIN}/full_transcript/${payload}`);
    // commit('setDirectVideoTranscription', video.data.hits.hits)
  },
  async getDirectVideoNotes({ commit }, payload) {
    // let model = `videoId: "${payload.id}", id: "${this.getters['general/user_meta'].id}"`
    // if(payload.limit){
    //   model += `, limit: ${payload.limit}`
    // }
    let result = {
      data: {
        getNotesVideo: [
          "bla"
        ]
      }
    }

    console.log(payload);
    // const result = await mainClient.query(getNotesVideo(model))
    commit('setDirectVideoNotes', result.data.getNotesVideo.items)
  },
  async getDirectVideoUsers({ commit }) {
    commit('setDirectVideoUsers', [])
    let users = await axios.get('/dummy-data/users.json')
    commit('setDirectVideoUsers', users.data.users)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
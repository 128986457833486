import { warningToastConfig } from '@/helpers/constants'

const state = {
  search: {
    searchPresets: {
      buckets: {},
      bucketsLoaded: false
    },
    searchState: {
      term: "",
      online: true,
      bucket: "",
      channels: [], // move to presets
      selectedChannelIds: [],
      channels_filter: false,
      filters: ['show_online', 'show_deleted_by_yt', 'show_deleted_by_owner'],
      sort: "date:desc",
      date: [1325400579, Math.floor(Date.now() / 1000)],
      from: 0,
      size: 40,
      type: ""
    },
    pictureInPicture: {
      show: false,
      activeVideo: "",
      timestamp: 0,
      x: 101,
      y: 100
    },
    results: {
      total_results: 0,
      histogram: [],
    },
    loading: false,
    show_video_detail: false,
    video_detail_id: "",
    lines: [],
    savedLines: [],
    narrativeTracing: {
      saved: []
    }
  },
}
const getters = {
  getDates: (state) => state.search.searchState.date,
  searchPresets: (state) => state.search.searchPresets,
  searchState: (state) => state.search.searchState,
  searchChannels: (state) => state.search.searchState.channels,
  sortItem: (state) => state.search.searchState.sort,
  removedItems: (state) => state.search.searchState.filters,
  lines: (state) => state.search.lines,
  histogram: (state) => state.search.results.histogram,
  total_results: (state) => state.search.results.total_results,
  detailsState: (state) => state.search.show_video_detail,
  video_id: (state) => state.search.pictureInPicture.activeVideo,
  timestamp: (state) => state.search.pictureInPicture.timestamp,
  savedLines: (state) => state.search.savedLines,
  showVideo: (state) => state.search.pictureInPicture.show,
  loading: (state) => state.search.loading,
  isShowVideoDetail: (state) => state.search.show_video_detail,
  selectedChannelIds: (state) => state.search.searchState.selectedChannelIds,
  narrativeTracingSaved: (state) => state.search.narrativeTracing.saved,
}
const mutations = {
  setFrom(state, payload) { // refactor?
    state.search.searchState.from = payload;
  },
  loadMoreResults(state) {
    state.search.searchState.from = state.search.searchState.from + state.search.searchState.size;
  },
  setInitialSearchSettings(state, payload) {
    state.search.lines = [] // Remove any results in case of a second search or altered settings
    state.search.searchState.term = payload.term;
    state.search.searchState.bucket = payload.bucketId;
    state.search.searchState.type = payload.type;
    state.search.searchState.from = payload.from;
  },
  setInitialMetadata(state, payload) {
    state.search.results.total_results = payload.hits.total;
    state.search.results.histogram = payload.aggregations.mentions_over_time.buckets
  },
  setLines(state, payload) {
    const findThumb = (id) => {
      let chanThumb = "";
      state.search.searchState.channels.forEach((channel) => {
        if (channel.channelId === id) { chanThumb = channel.thumbnail; }
      })
      return chanThumb
    }

    for (let i = 0; i < payload.hits.hits.length; i++) {
      let line = payload.hits.hits[i]
      for (let n = 0; n < line.highlight.text.length; n++) {
        state.search.lines.push({
          line_id: `${line._id}_${Math.random()}`,
          videoId: line._id.split("$")[0],
          channelId: line._source.channelId,
          user: line._source.user,
          date: line._source.date,
          online: line._source.online,
          views: line._source.views,
          thumb: findThumb(line._source.channelId),
          textHTML: line.highlight.text[n],
          lang: line._source.lang,
          auto: line._source.auto
        });
      }
    }


    let indexCounter = 0
    state.search.lines.forEach((elem, index) => {
      let prevItem = state.search.lines[index - 1]
      if (prevItem) {
        if (elem.channelId != prevItem.channelId && elem.user != prevItem.user) {
          indexCounter = 0
        } else {
          indexCounter++
          elem.indexCounter = indexCounter
        }
      }
    })
  },
  setVideo(state, payload) {
    state.search.pictureInPicture.activeVideo = payload.video_id;
    state.search.pictureInPicture.timestamp = payload.timestamp;
    state.search.pictureInPicture.show = payload.show;
  },
  setLoading(state, payload) { // refactor into single func?
    state.search.loading = payload;
  },
  setChannelsIDs(state, payload) {
    if (state.search.searchState.selectedChannelIds.indexOf(payload) == -1) state.search.searchState.selectedChannelIds.push(payload)
    else state.search.searchState.selectedChannelIds = state.search.searchState.selectedChannelIds.filter(elem => elem != payload)
  },
  setDate(state, payload) {
    state.search.searchState.date = JSON.parse(JSON.stringify(payload))
  },
  setSortItem(state, payload) { // refactor into single func?
    state.search.searchState.sort = payload
  },
  setRemovedItems(state, payload) {
    if (state.search.searchState.filters.indexOf(payload) == -1) state.search.searchState.filters.push(payload)
    else state.search.searchState.filters = state.search.searchState.filters.filter(elem => elem != payload)
  },
  setRemovedTwoItems(state, payload) {
    let isPresent = 0
    payload.forEach(elem => {
      if (isPresent != -1) isPresent = state.search.searchState.filters.indexOf(elem)
    })
    if (isPresent == -1) {
      state.search.searchState.filters = state.search.searchState.filters.filter(elem => elem != payload[0] && elem != payload[1])
      state.search.searchState.filters.push(...payload)
    } else {
      state.search.searchState.filters = state.search.searchState.filters.filter(elem => elem != payload[0] && elem != payload[1])
    }
  },
  showVideoDetail(state, payload) {
    state.search.show_video_detail = payload.show;
    state.search.video_detail_id = payload.video_id
    state.search.pictureInPicture.timestamp = payload.timestamp
  },
  setInitialBuckets(state, payload) {
    state.search.searchPresets = {
      buckets: payload,
      bucketsLoaded: true
    }
  },
  setInitialChannels(state, payload) {
    state.search.searchState.channels = payload.data;
    state.search.searchState.selectedChannelIds = payload.channelIds;
  },
  setChannelsInterface(state, payload) {
    state.search.searchState.selectedChannelIds = payload;
  },
  setBucket(state, payload) {
    state.search.searchState.bucket = payload.bucketId;
  },
  mutateNarrativeTracing(state, payload) {
    // console.log(state.search.narrativeTracing.saved)

    if (payload.action === 'create') {
      state.search.narrativeTracing.saved.push(payload);
    }

    if (payload.action === 'delete') {
      state.search.narrativeTracing.saved = state.search.narrativeTracing.saved.filter((el) => el.videoId != payload.videoId); 
      // console.log(saved);
    }

  }
}
const actions = {
  async getChannels(context, payload) {
    const { data } = await this._vm.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get_channels_bucket/${payload}`)
    let arr = [];

    data.forEach((chan) => { arr.push(chan.channelId) });
    context.commit('setInitialChannels', {
      data: data,
      channelIds: arr
    });
  },
  setSearchValues(context, payload) {
    context.commit('setInitialSearchSettings', payload)
  },
  async fetchLines(context, payload) {
    const selectedChannelIds = context.state.search.searchState.selectedChannelIds;

    if (selectedChannelIds.length === 0) {
      if (context.state.search.searchState.bucket) {
        await context.dispatch('getChannels', context.state.search.searchState.bucket);
      }
    }

    const selectedChannels = context.state.search.searchState.selectedChannelIds.map((name) => {
      return name.toLowerCase()
    });

    let initialRequest = true;
    if (payload.followup) {
      initialRequest = false;
      context.commit('loadMoreResults')
    }

    context.commit('setLoading', true);
    const { data } = await this._vm.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/search_bucket`, {
      "buckets": selectedChannels,
      "from": context.state.search.searchState.from,
      "bucketId": context.state.search.searchState.bucket,
      "type": context.state.search.searchState.type,
      "size": 40,
      "sort": context.state.search.searchState.sort,
      "term": context.state.search.searchState.term,
      "dateFrom": state.search.searchState.date[0],
      "dateTo": state.search.searchState.date[1],
      "htmlRender": true
    });

    if (data.error) {
      console.log('ERROR')
      if (data.error.reason.includes('no such index')) {
        let channels = context.state.search.searchState.selectedChannelIds;
        let newChannels = channels.filter((chan) => chan.toLowerCase() !== data.error.index);
        this._vm.$toasted.show(`Channel ${data.error.index} has no video's or has not been indexed yet.`, warningToastConfig)
        console.log(newChannels);
        context.commit('setChannelsInterface', newChannels);
        return context.dispatch('fetchLines', payload);
      }
    }

    context.commit('setLines', data);
    if (initialRequest) context.commit('setInitialMetadata', data)
    context.commit('setLoading', false);
  },
  activateVideo(context, payload) {
    context.commit('setVideo', payload)
  },
  showVideoDetail(context, payload) {
    context.commit('showVideoDetail', payload)
  },
  narrativeTracingMutation(context, payload) {
    context.commit('mutateNarrativeTracing', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
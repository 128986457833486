<template>
  <div class="flex flex-wrap min-h-screen">
    <div class="w-1/2 bg-black"></div>
    <div class="w-1/2 flex items-center">
      <div class="wrapper mx-16 w-full">
        <img :src="require('../../assets/icons/raditubelogoNoAnimation.svg')" alt=""> 
        <h2 class="text-2xl font-grey-darker-100 mt-10 leading-none"> {{ title }} </h2>
        <div class="flex flex-wrap mt-7">
          <!-- <button 
            @click="googleLogin"
            class="flex items-center text-base h-10 px-4 rounded-2xl base-bg mr-8"
          >
            <img :src="require('../../assets/icons/google_colored.svg')" alt=""> 
            <span class="ml-2"> {{ googleText }} </span>
          </button>
          <button 
            @click="twitterLogin"
            class="flex items-center text-base h-10 px-4 rounded-2xl base-bg"
          >
            <img :src="require('../../assets/icons/twitter_coloerd.svg')" alt=""> 
            <span class="ml-2"> {{ twitterText }} </span>
          </button> -->
        </div>
        <p class="font-grey-darker-100 text-base relative flex items-center"> 
          <span class="whitespace-nowrap pr-4"> {{ emailText }} </span>
          <span class="title_with_line"></span>
        </p>
        <form class="mt-6" @submit.prevent="login">
          <div class="w-full mt-4">
            <label for="email" class="w-full block text-base font-grey-darker-200 mb-2">Email</label>
            <input 
              v-model="email"
              type="email" 
              id="email" 
              name="email"  
              class="h-8 base-border-grey-200 rounded border pl-4 outline-none base-font-gray-100 text-base"
              placeholder="email@email.com"
              required
            >
          </div>
          <div class="w-full mt-4 relative">
            <label for="password" class="w-full block text-base font-grey-darker-200 mb-2">Password</label>
            <!-- <v-popover
              trigger="manual"
              :open="showPopover"
              offset="16"
              :auto-hide="false"
              id="password-tooltip"
              placement='right-center'
            > -->
              <input
                v-model="password"
                type="password" 
                id="password" 
                name="password"
                class="tooltip-target h-8 base-border-grey-200 rounded border pl-4 outline-none base-font-gray-100 text-base"
                placeholder="*************"
                required
                pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                @input="validatePassword($event.target.value)"
              >
              <template slot="popover">
                <div class="tooltip-content">
                  <p 
                    class="font-sansDemi text-sm"
                    :class="{
                      'base-font-gray-light-500': isAllChars,
                      'font-grey-darker-500': !isAllChars
                    }"
                  >
                    At least 8 characters
                  </p>
                  <p 
                    class="font-sansDemi text-sm"
                    :class="{
                      'base-font-gray-light-500': isOneLower,
                      'font-grey-darker-500': !isOneLower
                    }"
                  >
                    At least one lower character
                  </p>
                  <p 
                    class="font-sansDemi text-sm"
                    :class="{
                      'base-font-gray-light-500': isOneCapital,
                      'font-grey-darker-500': !isOneCapital
                    }"
                  >
                    At least one capital character
                  </p>
                  <p 
                    class="font-sansDemi text-sm"
                    :class="{
                      'base-font-gray-light-500': isOneSpecial,
                      'font-grey-darker-500': !isOneSpecial
                    }"
                  >
                    At least one special character
                  </p>
                </div>
              </template>
            <!-- </v-popover> -->
          </div>

          <div v-if="!isLogin" class="w-full mt-6 flex items-center">
            <input 
              v-model="agree"
              id="agree" 
              name="agree"
              type="checkbox" 
              class="hidden checkboxGrey"
            >
            <label for="agree" class="labelGrey block cursor-pointer">
              <p class="text-sm font-bold font-grey-darker-300 ml-8"> I agree with RadiTube’s Terms of Service </p>
            </label>
          </div>
          <button class="flex items-center justify-center font-sans text-base base-bg rounded-3xl w-24 h-8 mt-6" type="submit"> 
            {{ btnText }} 
          </button>
        </form>

        <router-link to="/forgot-password" class="block mt-8 text-base font-bold linkColor"> Forgot Password? </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { warningToastConfig } from '@/helpers/constants'
  // import ToastMessages from '@/data/toast_messages.json'

  export default {
    name: 'Login',
    props: [ 'googleText', 'twitterText', 'emailText', 'title', 'btnText', 'isLogin' ],
    data(){
      return{
        email: '',
        password: '',
        agree: false,
        isAllChars: false,
        isOneCapital: false,
        isOneLower: false,
        isOneSpecial: false
      }
    },
    methods: {
      login() {
        // this.$auth.loginWithRedirect();
        this.$webAuth.login({
          realm: 'Username-Password-Authentication',
          password: this.password,
          email: this.email
        }, (err) => {
          if(err) this.$toasted.show(err.error_description, warningToastConfig)
        });
        // if(this.isLogin){
        //   this.$webAuth.login({
        //     realm: 'Username-Password-Authentication',
        //     password: this.password,
        //     email: this.email
        //   });
        // }else{
        //   if(this.agree){
        //     this.$webAuth.signup({
        //       connection: 'Username-Password-Authentication',
        //       password: this.password,
        //       email: this.email
        //     }, (err) => {
        //         if (err) {
        //           let message = JSON.parse(err.original.message).message ? JSON.parse(err.original.message).message : JSON.parse(err.original.message).description
        //           this.$toasted.show(message, warningToastConfig)
        //           return;
        //         }else{
        //           this.$webAuth.login({
        //             realm: 'Username-Password-Authentication',
        //             password: this.password,
        //             email: this.email,
        //             redirect_uri: this.isLogin ? window.location.origin : `${window.location.origin}/onboardingtypeform`,
        //           });
        //         }
        //     });
        //   }else{
        //     this.$toasted.show(ToastMessages.login.agree, warningToastConfig)
        //   }
        // }
      },
      // googleLogin(){
      //   this.$webAuth.authorize({
      //     connection: 'google-oauth2',
      //     redirect_uri: this.isLogin ? window.location.origin : `${window.location.origin}/onboardingtypeform`,
      //     responseType: 'token id_token',
      //     scope: 'openid profile read:current_user_metadata'
      //   });
      // },
      // twitterLogin(){
      //   this.$webAuth.authorize({
      //     connection: 'twitter',
      //     redirect_uri: this.isLogin ? window.location.origin : `${window.location.origin}/onboardingtypeform`,
      //     responseType: 'token id_token',
      //     scope: 'openid profile read:current_user_metadata'
      //   });
      // },
      validatePassword(text){
        this.isAllChars = text.length >= 8
        this.isOneCapital = /(?=.*[A-Z])/.test(text)
        this.isOneLower = /(?=.*[a-z])/.test(text)
        this.isOneSpecial = /[*@!#%&$()^~{}]+/.test(text)
      } 
    },
    computed: {
      showPopover(){
        return !(this.isAllChars && this.isOneCapital && this.isOneLower && this.isOneSpecial)
      }
    }
  }
</script>

<style lang="scss" scoped>
.title_with_line{
  width: 100%;
  height: 1px;
  background-color: #BEBEBE;
}
.labelGrey{
  &:before{
    left: 0;
    top: 2px;
  }
}
</style>
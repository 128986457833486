// import axios from 'axios';

const state = {
  auth: "",
  user: {
    onboarding: true,
    meta: {},
    settings: {
      close_video_scroll: true,
      close_details_scroll: true
    }
  },
  selectedBucket: {
    bucketId: null,
    title: '',
    bucketType: '',
    channels: 0,
    deepStorage: false
  }
}
const getters = {
  auth: (state) => state.auth,
  user_meta: (state) => state.user.meta,
  avatarImg: (state) => state.user.meta.picture,
  closePiPScroll: (state) => state.user.settings.close_video_scroll,
  closeDetailsScroll: (state) => state.user.settings.close_details_scroll,
  userMetaLabels: (state) => {
    if(state.user.meta['https://raditube.com/user_metadata'] && state.user.meta['https://raditube.com/user_metadata'].labels){
      return state.user.meta['https://raditube.com/user_metadata'].labels
    }else{
      return []
    }
  },
  // userAppData: (state) => state.user.meta['https://raditube.com/app_metadata']
  userAppData: (state) => {
    return {
      buckets: state.user.buckets,
      onboarding_complete: false,
      plan: state.user.plan,
      signedUp: true,
      team: state.user.team,
      role: state.user.role,
      organization: state.user.organization
    }
  },
  selectedBucket: (state) => state.selectedBucket,
}
const mutations = {
  setAuth(state, payload) { // refactor?
    state.auth = payload;
  },
  setOnboarding(state, payload) { // refactor?
    state.user.onboarding = payload;
  },
  storeMetadata(state, payload) { // refactor?
    state.user.meta = payload;
    if (state.user.meta.sub) {
      state.user.meta.id = state.user.meta.sub
    }
    if (state.user.meta.id) {
      state.user.meta.sub = state.user.meta.id
    }
  },
  setSelectedBucket(state, payload) {
    state.selectedBucket = payload;
  }
}
const actions = {
  async authorizeAuth0(context) {
    return this._vm.$auth.getTokenSilently()
      .then((res) => {
        context.commit('setAuth', res);
      })
  },
  async storeUserMetadata(context, payload) {
    await context.dispatch('authorizeAuth0');

    return this._vm.$auth.getIdTokenClaims()
      .then((res) => {
        if (res['https://raditube.com/logins'] <= 1) { // or onboarding is done
          context.commit('setOnboarding', true)
        }

        // context.commit('storeMetadata', res);
        context.commit('storeMetadata', payload);
      })
  },
  async loggedIn(context, payload) {
    await context.dispatch('storeUserMetadata', payload);
  },
  // track(context, payload) {
  //   const email = context.state.user.meta.email;
  //   // axios.get(`${process.env.VUE_APP_API_DOMAIN}/trackpage/${btoa(payload.name)}/e/${btoa(email)}`);
  // },
  // async getTeams(){
  //   let teamsList = await axios.get('/dummy-data/teams/available-teams.json');
  //   return JSON.parse(JSON.stringify(teamsList.data))
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
<template>
  <div
    id="app"
    class="app_js"
    :class="{
      'main-gradient': isGradient
    }"
  >
    <template v-if="!$auth.loading">
      <vue-extend-layouts v-if="$auth.isAuthenticated"/>
      <router-view v-else/>
    </template>
  </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout'
import { eventBus } from '@/main'

export default {
  name: 'app',
  data(){
    return{
      isGradient: false
    }
  },
  components: {
    VueExtendLayouts
  },
  methods: {
    toggleGradient(arg){
      this.isGradient = !arg
    },
  },
  mounted(){
    eventBus.$on('collapseProfileNav', this.toggleGradient)
  },
  beforeDestroy(){
    eventBus.$off('collapseProfileNav', this.toggleGradient)
  },
}
</script>

<style lang="scss">
@import '~@/styles/fonts.scss';
@import '~@/styles/colors.scss';
@import '~normalize.css';
// @import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';

* {
  box-sizing: border-box;
  font-size: 16px;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.main-gradient{
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 100vh;
}

.zoom-big-appear,
.zoom-big-enter,
.zoom-big-leave {
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.zoom-big-enter {
    -webkit-animation-name: antZoomBigIn;
    animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.zoom-big-appear,
.zoom-big-enter {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: cubic-bezier(.08, .82, .17, 1);
    animation-timing-function: cubic-bezier(.08, .82, .17, 1);
}

@-webkit-keyframes antZoomBigIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes antZoomBigIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.zoom-right-appear,
.zoom-right-enter,
.zoom-right-leave {
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.zoom-right-enter {
    -webkit-animation-name: antZoomRightIn;
    animation-name: antZoomRightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
    -webkit-animation-name: antZoomRightOut;
    animation-name: antZoomRightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}
.zoom-right-appear,
.zoom-right-enter {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: cubic-bezier(.08, .82, .17, 1);
    animation-timing-function: cubic-bezier(.08, .82, .17, 1);
}
.zoom-right-leave {
    -webkit-animation-timing-function: cubic-bezier(.78, .14, .15, .86);
    animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}
@-webkit-keyframes antZoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.2);
        transform: scale(.2);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes antZoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.2);
        transform: scale(.2);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes antZoomOut {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale(.2);
        transform: scale(.2);
    }
}
@keyframes antZoomOut {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale(.2);
        transform: scale(.2);
    }
}
@-webkit-keyframes antZoomBigIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes antZoomBigIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes antZoomBigOut {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
}
@keyframes antZoomBigOut {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
}
@-webkit-keyframes antZoomUpIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
    to {
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes antZoomUpIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
    to {
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes antZoomUpOut {
    0% {
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
}
@keyframes antZoomUpOut {
    0% {
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
}
@-webkit-keyframes antZoomLeftIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
    to {
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes antZoomLeftIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
    to {
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes antZoomLeftOut {
    0% {
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
}
@keyframes antZoomLeftOut {
    0% {
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
}
@-webkit-keyframes antZoomRightIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
    to {
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes antZoomRightIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
    to {
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes antZoomRightOut {
    0% {
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
}
@keyframes antZoomRightOut {
    0% {
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
}
@-webkit-keyframes antZoomDownIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
    to {
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes antZoomDownIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
    to {
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes antZoomDownOut {
    0% {
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
}
@keyframes antZoomDownOut {
    0% {
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
}

</style>

import moment from 'moment'

function formatTimeText(arg) {
  let hours = 0
  let mins = Number(arg.split(':')[0])
  let formattedMins = 0
  let formattedSeconds = Number(arg.split(':')[1])
  formattedSeconds = formattedSeconds < 10 ? `0${formattedSeconds}` : formattedSeconds
  if(mins >= 60){
    hours = Math.floor(mins/60)
    formattedMins = mins - (hours * 60)
  }else{
    formattedMins = mins
  }

  hours = hours < 10 ? `0${hours}` : hours
  formattedMins = formattedMins < 10 ? `0${formattedMins}` : formattedMins

  return `${hours}:${formattedMins}:${formattedSeconds}`
}

function formatSeconds(arg) {
  let localSeconds = Number(arg)
  // let hours = 0
  let minutes = 0
  let seconds = 0

  seconds = arg % 60
  localSeconds -= seconds
  minutes = localSeconds / 60
  // hours = minutes / 60

  seconds = Number(seconds).toFixed(2)
  seconds = Number(seconds)
  
  return `${minutes}:${seconds < 10 ? `0${Math.round(seconds)}` : Math.round(seconds)}`
  // return `${hours}:${minutes}:${seconds < 10 ? `0${Math.round(seconds)}` : Math.round(seconds)}`
}

function formatNotesWithTimestamps(arg) {
  let timeIndex = arg.indexOf(':')
  if(timeIndex != -1){
    let splited = arg.split(' ')
    let stringNew = []
    let splittedItem = []

    let hours = 0
    let mins = 0
    let seconds = 0
    let resultTime = 0

    splited.forEach((elem) => {
      if(elem.indexOf(':') == -1){
        stringNew.push({ text: elem, time: false})
      }else{
        splittedItem = elem.split(':')

        if(splittedItem.length == 2){
          mins = Number(splittedItem[0])
          seconds = Number(splittedItem[1])
          resultTime = (mins * 60) + seconds
          stringNew.push({ text: `${resultTime}`, time: true})
        }else if(splittedItem.length == 3){
          hours = Number(splittedItem[0])
          mins = Number(splittedItem[1])
          seconds = Number(splittedItem[2])
          resultTime = (hours * 3600) + (mins * 60) + seconds
          stringNew.push({ text: `${resultTime}`, time: true})
        }
      }
    })
    return stringNew
  }else{
    return [{ text: arg }]
  }
}

function formatDate(date){
  let minutes = moment().diff(moment(date), 'minutes')
  const minutesInHour = 60
  const hoursInDay = 24
  let minutesInDay = 24 * minutesInHour
  if(minutes < minutesInHour){
    if( minutes == 1) return `1 minute`
    else return `${minutes} minutes`
  }
  else if(minutes < minutesInDay) { // 1440 is minutes count for 24 hours
    if( minutes == minutesInHour) return `1 hour`
    else return `${Math.round(minutes / minutesInHour)} hours`
  }
  else if(minutes >= minutesInDay) {
    if(minutes == minutesInDay) return `1 day`
    else return `${Math.round((minutes/minutesInHour)/hoursInDay)} days`
  }
}

function formatChannelURLToID(url){
  let conditions = ['/c/', '/channel/', '/user/']
  let conditionsResult = {}
  conditions.forEach(elem => {
    conditionsResult[elem] = url.indexOf(elem)
  })

  let cuttedString = ''
  let parsedResult = ''

  for (let item in conditionsResult) {
    if(conditionsResult[item] != -1){
      cuttedString = url.slice(conditionsResult[item] + item.length)
      parsedResult = cuttedString.split('/')[0]
      break
    } 
  }
  return parsedResult
}

function formatNumberToSmallView(number){
  if(number > 1000000){
    return `${(number / 1000000).toFixed(1)} M`
  }else if(number >= 1000){
    return `${Math.floor(number / 1000)} K`
  }else if(number < 1000 && number >= 100){
    return `${String((number / 1000)).slice(0, 3)} K`
  }else return '<100'
}

function capitalizeFirstLetter(str){
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export { formatSeconds, formatNotesWithTimestamps, formatDate, formatChannelURLToID, formatTimeText, formatNumberToSmallView, capitalizeFirstLetter }
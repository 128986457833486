import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import general from '@/store/general'
import videoDetails from '@/store/video-details'
import discovery from '@/store/discovery'
import settings from '@/store/settings'
import search from '@/store/search'
import bucket from '@/store/bucket'

const bucketState = createPersistedState({
  paths: ['bucket']
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    general,
    videoDetails,
    discovery,
    settings,
    search,
    bucket
  },
  plugins: [bucketState]
})
